import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { deleteDish, getAllDishes, updateDish, getMe } from '../../shared/api/user';
import { useState, useEffect } from 'react';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import "../Orders/Orders.css";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import noImage from '../../shared/images/nofood.png';

export default function BasicTable() {
  const [Dishes, setDishes] = useState([]);
  const navigate = useNavigate();
  const [isempty, setisempty] = useState(false);
  const [isloading, setisloading] = useState(true);

  const init = async () => {
    let user = await getMe();
    let rows = await getAllDishes(user.data.user._id);
    setDishes(rows.data.dishes);
    if (!rows.data.dishes.length) {
      setisempty(true)
    }
    setisloading(false);
  }

  useEffect(() => {
    init();
  }, []);

  async function deleteDishById(id) {
    let status = await updateDish(id, { isDisabled: true });
    init();
  }

  function editDish(id) {
    navigate(`/dish/edit/${id}`);
  }

  return (
    <div>
      <Button onClick={() => window.location.href = '/dish'} sx={{ mb: 2, fontWeight: "600", color: '#ee3044', border: '1px solid #ee3044', "&:hover": { border: "1px solid red" } }} variant="outlined" style={{ float: "right" }} startIcon={<AddIcon sx={{ color: '#ee3044' }} />}>
        Add new dish
      </Button>
      {isloading && <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress color="warning" />
      </Box>}
      {(isempty && !isloading) && <div style={{ fontWeight: '600', textAlign: 'center' }}>No Dishes! You can add from menu.</div>}
      {(!isempty && !isloading) && <TableContainer className='table-container' component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className='table-head'>
            <TableRow>
              <TableCell className='table-call-row'>Dish</TableCell>
              <TableCell className='table-call-row' align="right">Dish Name</TableCell>
              <TableCell className='table-call-row' align="right">Description</TableCell>
              <TableCell className='table-call-row' align="right">Price</TableCell>
              <TableCell className='table-call-row' align="right">Cuisine</TableCell>
              <TableCell className='table-call-row' align="right">Category</TableCell>
              {/* <TableCell className='table-call-row' align="right">Addons</TableCell> */}
              <TableCell className='table-call-row' align="right">Edit Dish</TableCell>
              <TableCell className='table-call-row' align="right">Delete Dish</TableCell>
              <TableCell className='table-call-row' align="right">Dish Added Time</TableCell>
              <TableCell className='table-call-row' align="right">Coupon</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Dishes.map((row) => (
              <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className='table-call-row-data' size='small' component="th" scope="row">
                  {row.picture && <img style={{ height: "50px", width: "50px", borderRadius: "50%" }} src={row.picture} ></img>}
                  {!row.picture && <img style={{ height: "50px", width: "50px", borderRadius: "50%" }} src={noImage} ></img>}
                </TableCell>
                <TableCell className='table-call-row-data' align="right">{row.dishName}</TableCell>
                <TableCell className='table-call-row-data' align="right">{row.description}</TableCell>
                {row.price > 0 && <TableCell className='table-call-row-data' align="right">{`${process.env.REACT_APP_CURRENCY}${row.price}`}</TableCell>}
                {row.price === 0 || row.price === null &&  <TableCell className='table-call-row-data' align="right">{`${process.env.REACT_APP_CURRENCY}${row.sizes[0]?.price}`}</TableCell>}
                <TableCell className='table-call-row-data' align="right">{row.cuisine?.title}</TableCell>
                <TableCell className='table-call-row-data' align="right">{row?.category}</TableCell>
                {/* <TableCell className='table-call-row-data' align="right"><Button disabled variant="text"><RestaurantMenuIcon sx={{ color: '#CB2704' }}></RestaurantMenuIcon></Button></TableCell> */}
                <TableCell className='table-call-row-data' align="right"><Button onClick={() => editDish(row._id)} variant="text"><EditIcon sx={{ color: '#CB2704' }}></EditIcon></Button></TableCell>
                <TableCell className='table-call-row-data' align="right"><Button onClick={() => deleteDishById(row._id)} variant="text"><DeleteIcon sx={{ color: '#CB2704' }}></DeleteIcon></Button></TableCell>
                <TableCell className='table-call-row-data' align="right">{moment(row.createdAt).format('MMM Do YY, h:mm a')}</TableCell>
                <TableCell className='table-call-row-data' align="right">{row.coupon}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}
    </div>

  );
}