import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { deleteOrder, getOrdersList, getProfitDetails } from '../../shared/api/user';
import { useState, useEffect } from 'react';
import moment from 'moment';
import "../Orders/Orders.css";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function BasicTable() {
  const [orders, setOrders] = useState([]);
  const [Offset, setOffset] = useState(0);
  const navigate = useNavigate();
  const [isempty, setisempty] = useState(false);
  const [isloading, setisloading] = useState(true);
  const init = async () => {
    let rows = await getOrdersList(Offset, 30, 'COMPLETED');
    setOrders(rows.data.orders);
    if (!rows.data.orders.length) {
      setisempty(true)
    }
    setisloading(false);
  }

  useEffect(() => {
    init();
    const interval = setInterval(() => {
      init();
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  async function deleteOrderById(id) {
    let status = await deleteOrder(id);
    init();
  }

  const details = async (id) => {
    navigate(`/order/${id}`);
  }

  async function paginationClicked(e) {
    setOffset(Number(e.target.innerText * 10))
    let rows = await getOrdersList(Offset);
    let data = rows.data.orders.filter(function (e) {
      return e.orderStatus === 'COMPLETED';
    });
    setOrders(data);
  }

  return (
    <div>
      {isloading && <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress color="warning" />
      </Box>}
      {(isempty && !isloading) && <div style={{ fontWeight: '600', textAlign: 'center' }}>You Do Not Have Any Completed Orders Yet!</div>}
      {(!isempty && !isloading) && <TableContainer className='table-container' component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className='table-head'>
            <TableRow>
              <TableCell className='table-call-row' >Dish</TableCell>
              <TableCell className='table-call-row' align="right">Customer Name</TableCell>
              <TableCell className='table-call-row' align="right">Dish Name</TableCell>
              <TableCell className='table-call-row' align="right">Order Status</TableCell>
              <TableCell className='table-call-row' align="right">Order Type</TableCell>
              <TableCell className='table-call-row' align="right">Payment Status</TableCell>
              <TableCell className='table-call-row' align="right">Total Price</TableCell>
              {/* <TableCell align="center">Details</TableCell> */}
              <TableCell className='table-call-row' align="right">Order Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((row) => (
              <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} size='small' component="th" scope="row">
                  <img style={{ height: "45px", width: "45px", borderRadius: "50%" }} src={row.orderItems[0]?.dish.picture} ></img>
                </TableCell>
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{row.user?.firstName} {row.user?.lastName}</TableCell>
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{row.orderItems[0]?.dish.dishName.substring(0, 25)}...</TableCell>
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{row.orderStatus}</TableCell>
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{row.orderType}</TableCell>
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right"><span className={`${row?.paymentStatus === 'PAID' ? 'payment-status' : 'Npayment-status'}`}>{row?.paymentStatus}</span></TableCell>
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{process.env.REACT_APP_CURRENCY}{(row.subTotal - row.chefServiceFee).toFixed(2)}</TableCell>
                {/* <TableCell align="right"><Button sx={{ color: '#CB2704' }} onClick={() => details(row._id)} variant="text"><InfoIcon sx={{ color: '#CB2704' }}></InfoIcon></Button></TableCell> */}
                <TableCell className='table-call-row-data' onClick={() => details(row._id)} style={{ cursor: 'pointer' }} align="right">{moment(row.createdAt).format('MMM Do YY, h:mm a')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* <Stack spacing={3}>
        <Pagination onClick={(event) => paginationClicked(event)} count={10} color="primary" />
      </Stack> */}
      </TableContainer>}
    </div>
  );
}