import axios from 'axios';
import { API_URL } from '../constants/config';

// let token = '';

// if (localStorage.getItem('token')) {
//   token = localStorage.getItem('token');
// }

const instance = axios.create({ 
  baseURL: API_URL,
  headers: {
    'Content-type': 'application/json'
  },});
instance.interceptors.request.use(function (config) {
  config.headers.Authorization = localStorage.getItem('token');
  return config;
});

export default instance