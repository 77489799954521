import React from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import './Autocomplete.css';
import { useState, useEffect } from 'react';
import { autoComplete, getMe, placeDetail, updateUser } from '../../../shared/api/user';
import { v4 as uuid } from 'uuid';

function Autocomplete() {
    const [User, setUser] = useState([]);
    const [Adress, setAdress] = useState("Enter address");
    const [items, setitems] = useState([]);
    const [uuid4, setuuid4] = useState('');
    const init = async () => {
        let user = await getMe();
        setUser(user.data.user);
        if (user.data.user.streetAddress) {
            setAdress(user.data.user.streetAddress)
        }
        setuuid4(uuid())
    }

    useEffect(() => {
        init();
    }, []);

    const handleOnSearch = async (string, results) => {
        let test = []
        let result = await autoComplete(string, uuid4);
        for (let i = 0; i < result?.data?.places?.predictions.length; i++) {
            test.push({
                id: i,
                name: result?.data?.places?.predictions[i]?.description,
                placeId: result?.data?.places?.predictions[i]?.place_id,
            })
        }
        setitems(test);
    }

    const handleOnSelect = async (item) => {
        let place = await placeDetail(item.placeId, uuid4)
        let body = {
            streetAddress: place.data.places.result.formatted_address,
            // location: {
            //     type: "Point",
            //     coordinates: [place.data.places.result.geometry.location.lng, place.data.places.result.geometry.location.lat]
            // },
            address: {
                street: place.data.places.result.formatted_address,
            }
        }
        await updateUser(User._id, body);
    }
    const formatResult = (item) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    return (
        <div>
            <div style={{ width: 412, position: 'sticky', zIndex: '100' }}>
                <div className='address-field'>Address</div>
                <ReactSearchAutocomplete
                    items={items}
                    onSearch={handleOnSearch}
                    onSelect={handleOnSelect}
                    formatResult={formatResult}
                    placeholder={Adress}
                />
            </div>
        </div>
    )
}

export default Autocomplete;