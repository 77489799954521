import * as React from 'react';
import "./Dashboard.css";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NestedList from '../ListItems/listItems';
import { Outlet } from "react-router-dom";
import Menu from '../Menu/Menu';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import WarningIcon from '@mui/icons-material/Warning';
import { getMe, getPopular, verifyEmail, verificationEmail } from '../../shared/api/user';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { inputLabelClasses } from "@mui/material/InputLabel";
import CustomizedSnackbars from '../Snackbar/Snackbar';
import SpeechRecognitionComponent from '../speach/speach';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://forunpoint.com.pk/">
        ForunPoint
      </Link>{' '}
      {new Date().getFullYear()}
      {'. All Rights Reserved. | v1.0.0'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

function DashboardContent(props) {

  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#CB2704"
      }
    }
  }

  const [User, setUser] = useState([]);
  const [TopDishes, setTopDishes] = useState([]);
  const [last24, setLast24] = useState([]);
  const [isempty, setisempty] = useState(false);
  const [isloading, setisloading] = useState(true);
  const [stripeConnect, setStripeConnect] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isAddress, setIsAddress] = useState(false);
  const [opened, setOpened] = useState(false);
  const [open, setOpen] = useState(false);
  const [verificationCode, setVarificationCode] = useState('');
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const init = async () => {
    let user = await getMe();
    setUser(user.data.user);
    let orders = await getPopular(user.data.user._id);
    setLast24(orders.data.last24)
    let AllDishes = [];
    orders.data.orders.forEach(element => {
      AllDishes.push(element.orderItems[0].dish)
    });
    AllDishes = AllDishes.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t._id === value._id && t._id === value._id
      ))
    )
    AllDishes = AllDishes.slice(0, 3)
    setTopDishes(AllDishes)
    setisloading(false)
    if (!user.data.user.stripeConnectId) {
      setStripeConnect(true);
    }
    if (!user.data.user.isEmailVerified) {
      setIsEmailVerified(true);
    }
    if (!user.data.user.streetAddress) {
      setIsAddress(true);
    }
  }

  const handleClickCloses = () => {
    setOpened(false);

  };


  const handleEmailverification = () => {
    setOpened(true);
    verifyEmail({ email: User.email });

  };
  const submitVerification = async () => {
    try {
      await verificationEmail({ emailVerificationCode: verificationCode, email: User.email });
      setOpened(false)
      window.location.reload();
    } catch (err) {
      setError('Invalid Verification Code!');
      setTimeout(() => {
        setError('');
      }, 4000);
    }
  }
  useEffect(() => {
    init();
    if (!localStorage.getItem('token')) {
      navigate("/");
    }
  }, []);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      {error && <CustomizedSnackbars msg={error} isOpen={error !== ""}></CustomizedSnackbars>}
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open} style={{ backgroundColor: '#CB2704' }}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, fontWeight: '600' }}
            >
              Restaurant Console
            </Typography>
            <Menu />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <div>
              <img className='logo-dvour' src="../images/fp2.png" alt="Forunpoint" height={23} />
            </div>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <NestedList />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          {/* <SpeechRecognitionComponent /> */}
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            {location.pathname !== '/profile' && stripeConnect && <div className='warn-stripe'>
              <div ><WarningIcon className='icon-warn'></WarningIcon> You have to complete stripe connect setup in order to appear in search.</div>
            </div>}
            {location.pathname !== '/profile' && isAddress && <div className='warn-stripe'>
              <div ><WarningIcon className='icon-warn'></WarningIcon> Please update your address from menu.</div>
            </div>}
            {location.pathname !== '/profile' && isEmailVerified && <div className='warn-stripe'>
              <div onClick={() => handleEmailverification()}><WarningIcon className='icon-warn'></WarningIcon> Your email address is not verified. Click here to verify...</div>
            </div>}
            {/* <Orders/> */}

            <Outlet />
            <Copyright sx={{ pt: 4 }} />
          </Container>

        </Box>
        <Dialog open={opened} onClose={handleClickCloses}>
          <DialogTitle>Email Verification</DialogTitle>
          <DialogContent>
            <DialogContentText>
              A verification code has been sent to your email {User.email}. Please enter verification code below to verify your email adress.
            </DialogContentText>
            <TextField
              sx={style}
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#CB2704"
                  }
                }

              }}
              autoFocus
              margin="dense"
              id="name"
              label="Verification code"
              type="text"
              fullWidth
              onChange={(e) => {
                setVarificationCode(e.target.value);
              }}
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickCloses}>Cancel</Button>
            <Button onClick={submitVerification}>Submit</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
