import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import { getMe, createCoupon, updateUser } from '../../shared/api/user';
import { useState, useEffect } from 'react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EmailIcon from '@mui/icons-material/Email';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Select from '@mui/material/Select';
import demoVideo from '../d-vour-demo.mp4';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PasswordIcon from '@mui/icons-material/Password';
import CustomizedSnackbars from '../Snackbar/Snackbar';
import { inputLabelClasses } from "@mui/material/InputLabel";
import CategoryIcon from '@mui/icons-material/Category';
import './menu.css';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import noUser from '../../shared/images/nouser.png';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { Label } from 'recharts';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [opens, setOpens] = React.useState(false);
  const [opened, setOpened] = React.useState(false);
  const [FinancialBox, setFinancialBox] = React.useState(false);
  const [deliveryDisable, setdeliveryDisable] = React.useState(false);
  const [deliveryEnable, setdeliveryEnable] = React.useState(false);
  const [isDeliveryDisable, setisDeliveryDisable] = React.useState(false);
  const [User, setUser] = useState([]);
  const [StripeConnect, setStripeConnect] = useState(true);
  const [Coupon, setCoupon] = useState(false);
  const [financialPin, setfinancialPin] = useState(false);
  const [changeFinancialPin, setchangeFinancialPin] = useState(false);
  const [changeFinancialPinBox, setchangeFinancialPinBox] = useState(false);
  const [Pin, setPin] = useState('');
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const [couponName, setcouponName] = useState("");
  const [couponCode, setcouponCode] = useState("");
  const [couponAmount, setcouponAmount] = useState("");
  const [couponType, setcouponType] = useState("");
  const [Duration, setDuration] = useState("");
  const [Redemptions, setRedemptions] = useState("");
  const [OldPin, setOldPin] = useState('');
  const [NewPin, setNewPin] = useState('');
  const [CurrentPin, setCurrentPin] = useState('');
  const [error, setError] = useState("");
  const [longitude, setLongitude] = useState(1.1);
  const [latitude, setLatitude] = useState(1.1);
  const [openLocation, setOpenLocation] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const selectFieldStyles = {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CB2704",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CB2704",
    },
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    localStorage.removeItem('token');
    navigate("/");
    setAnchorEl(null);
  };
  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#CB2704"
      }
    }
  }

  const [openV, setOpenV] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpenV = () => {
    setOpenV(true);
  };

  const handleCloseV = () => {
    setOpenV(false);
  };

  const handleClickOpens = () => {
    setOpens(true);
  };

  const handleClickCloses = () => {
    setOpens(false);
  };

  const openLocationBox = () => {
    setOpenLocation(true);
  };

  const handleClickOpened = () => {
    setOpened(true);
  };

  const handleFinancialBox = () => {
    setFinancialBox(true);
  };

  const handleChangeFinancialBox = () => {
    setchangeFinancialPinBox(true);
  };


  const handleClickClosed = () => {
    setOpened(false);
    setFinancialBox(false);
    setchangeFinancialPinBox(false);
    setOpenLocation(false)
  };
  const closeDliveryDialogue = () => {
    setdeliveryDisable(false);
    setdeliveryEnable(false);
  };
  const showDliveryDialogue = () => {
    setdeliveryDisable(true);
  };

  const showDliveryDialogueEnable = () => {
    setdeliveryEnable(true);
  };

  const submitCoupon = async () => {
    let code = couponCode.toUpperCase();
    let body = {
      name: couponName,
      duration_in_months: Duration,
      code: code,
      max_redemptions: Redemptions
    }
    if (couponType === 'percentage') {
      body.percent_off = couponAmount;
    } else {
      let finalAmount = (couponAmount * 100).toFixed(0)
      body.amount_off = finalAmount;
      body.currency = 'USD'
    }
    await createCoupon(body);
    await updateUser(User._id, { restaurantCoupon: code });
    setOpened(false);
  };

  const submitPin = async (e) => {
    await updateUser(User._id, { financialPin: Pin });
    setFinancialBox(false);
  };
  const setDliveryOption = async () => {
    await updateUser(User._id, { isDeliveryDisabled: true });
    setdeliveryDisable(false);
    init();
  };

  const enableDliveryOption = async (e) => {
    await updateUser(User._id, { isDeliveryDisabled: false });
    setdeliveryEnable(false);
    init();
  };

  const submitChangePin = async (e) => {
    if (OldPin === CurrentPin) {
      await updateUser(User._id, { financialPin: NewPin });
      setchangeFinancialPinBox(false);
    } else {
      setError('Wrong Pin!');
      setTimeout(() => {
        setError('');
      }, 4000);
    }
  };


  const submitLocation = async (e) => {
    let body = {
      location: {
        type: "Point",
        coordinates: [parseFloat(longitude), parseFloat(latitude)]
      },
    }
    console.log(body)
    await updateUser(User._id, body);
    setOpenLocation(false);
    navigate("/dashboard");
  };

  const goToProfile = () => {
    setAnchorEl(null);
    navigate("/profile");
  }

  const goToSlots = () => {
    setAnchorEl(null);
    navigate("/edit/slots");
  }

  const connect = () => {
    setAnchorEl(null);
  }
  const categories = () => {
    navigate("/categories");
  }


  const init = async () => {
    let user = await getMe();
    setUser(user.data.user);
    if (user.data.user.stripeConnectId) {
      setStripeConnect(false);
    }
    if (user.data.user.isDeliveryDisabled) {
      setisDeliveryDisable(true);
    }
    if (!user.data.user.restaurantCoupon) {
      setCoupon(true);
    }
    if (!user.data.user.financialPin) {
      setfinancialPin(true);
    }
    if (user.data.user.financialPin) {
      setchangeFinancialPin(true);
    }

    if (user.data.user.financialPin) {
      setCurrentPin(user.data.user.financialPin);
    }

    if (user.data.user.location) {
      setLongitude(user.data.user.location.coordinates[0]);
      setLatitude(user.data.user.location.coordinates[1]);
    }
  }
  useEffect(() => {
    init();
  }, []);

  const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };

  const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
  };

  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    min-height: 80px;
    margin-top: 10px;
    padding: 12px;
    border-radius: 5px 5px 0 5px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );

  return (
    <React.Fragment>
      {error && <CustomizedSnackbars msg={error} isOpen={error !== ""}></CustomizedSnackbars>}
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {User.picture && <img style={{ height: "45px", width: "45px", borderRadius: "50%" }} src={User.picture} ></img>}
            {/* {!User.picture && <img style={{ height: "45px", width: "45px", borderRadius: "50%" }} src={noUser} ></img>} */}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,

            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          {User.chefType === 'PROFESSIONAL_CHEF' && <div> {User.firstName} {User.lastName}</div>}
          {User.chefType === 'RESTAURANT' && <div >{User.restaurantName}</div>}

        </MenuItem>
        <Divider />
        {/* <MenuItem onClick={handleClickOpens}>
          <ListItemIcon>
            <EmailIcon fontSize="small" sx={{ color: '#CB2704' }} />
          </ListItemIcon>
          Ask Admin
        </MenuItem> */}
        <MenuItem onClick={goToProfile}>
          <ListItemIcon>
            <Settings fontSize="small" sx={{ color: '#CB2704' }} />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={openLocationBox}>
          <ListItemIcon>
            <AddLocationAltIcon fontSize="small" sx={{ color: '#CB2704' }} />
          </ListItemIcon>
          Update Location
        </MenuItem>
        <MenuItem onClick={goToSlots}>
          <ListItemIcon>
            <AccessTimeFilledIcon fontSize="small" sx={{ color: '#CB2704' }} />
          </ListItemIcon>
          Time Availability
        </MenuItem>
        {/* <MenuItem onClick={handleClickOpenV}>
          <ListItemIcon>
            <PlayCircleIcon fontSize="small" sx={{ color: '#CB2704' }} />
          </ListItemIcon>
          Training Video
        </MenuItem> */}
        {Coupon && <MenuItem onClick={handleClickOpened}>
          <ListItemIcon>
            <AttachMoneyIcon fontSize="small" sx={{ color: '#CB2704' }} />
          </ListItemIcon>
          Add Coupon
        </MenuItem>}
        {financialPin && <MenuItem onClick={handleFinancialBox}>
          <ListItemIcon>
            <PasswordIcon fontSize="small" sx={{ color: '#CB2704' }} />
          </ListItemIcon>
          Set Financial Pin
        </MenuItem>}
        <MenuItem onClick={categories} >
          <ListItemIcon>
            <CategoryIcon fontSize="small" sx={{ color: '#CB2704' }} />
          </ListItemIcon>
          Dish Categories
        </MenuItem>
        {changeFinancialPin && <MenuItem onClick={handleChangeFinancialBox}>
          <ListItemIcon>
            <PasswordIcon fontSize="small" sx={{ color: '#CB2704' }} />
          </ListItemIcon>
          Change Pin
        </MenuItem>}
        {StripeConnect && <MenuItem onClick={connect}>
          <ListItemIcon>
            <AttachMoneyIcon fontSize="small" sx={{ color: '#CB2704' }} />
          </ListItemIcon>
          {/* <a href={`https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_M0jlPZZU1eilHnrPqnLsZfS1mCNj7u22&state=${User._id}&suggested_capabilities[]=transfers&stripe_user[email]=${User.email}&redirect_uri=https://api.d-vour.com/api/v1/payments/connect`}>Stripe Connect</a>  */}
          {/* <a href={`https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_M0jlu8BgmDyNsmo1ljEZnSzb6uHusfZr&state=${User._id}&suggested_capabilities[]=transfers&stripe_user[email]=${User.email}&redirect_uri=https://d-vour-api.herokuapp.com/api/v1/payments/connect`}>Stripe Connect</a> */}
          {/* <a href={`https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_M0jlu8BgmDyNsmo1ljEZnSzb6uHusfZr&state=${User._id}&suggested_capabilities[]=transfers&stripe_user[email]=${User.email}&redirect_uri=https://forunpoint-pk-dev-000da96aacf1.herokuapp.com/api/v1/payments/connect`}>Stripe Connect</a> */}
          <a href={`https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_M0jlu8BgmDyNsmo1ljEZnSzb6uHusfZr&state=${User._id}&suggested_capabilities[]=transfers&stripe_user[email]=${User.email}&redirect_uri=https://api-pakistan.d-vour.com/api/v1/payments/connect`}>Stripe Connect</a>


        </MenuItem>}
        {!isDeliveryDisable && <MenuItem onClick={showDliveryDialogue}>
          <ListItemIcon>
            <CarCrashIcon fontSize="small" sx={{ color: '#CB2704' }} />
          </ListItemIcon>
          Disable Delivery
        </MenuItem>}
        {isDeliveryDisable && <MenuItem onClick={showDliveryDialogueEnable}>
          <ListItemIcon>
            <CarCrashIcon fontSize="small" sx={{ color: '#CB2704' }} />
          </ListItemIcon>
          Enable Delivery
        </MenuItem>}
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" sx={{ color: '#CB2704' }} />
          </ListItemIcon>
          Logout
        </MenuItem>

      </Menu>
      <div>
        <Dialog open={opens} onClose={handleClickCloses}>
          <DialogTitle>Ask Admin</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ask any question from admin, or if you need any help write us your issue.
            </DialogContentText>
            <TextField
              sx={style}
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#CB2704"
                  }
                }

              }}
              autoFocus
              margin="dense"
              id="name"
              label="Subject"
              type="email"
              fullWidth

            />
            <StyledTextarea
              maxRows={5}
              fullWidth
              aria-label="maximum height"
              placeholder="Message"

            />
          </DialogContent>
          <DialogActions>
            <Button className='inmenu-btn' onClick={handleClickCloses}>Cancel</Button>
            <Button className='inmenu-btn' onClick={handleClickCloses}>Submit</Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog open={opened} onClose={handleClickCloses}>
          <DialogTitle>Add Coupon</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Add Chef/Restaurent discount coupon for menu.
            </DialogContentText>
            <Box sx={{ minWidth: 120, mt: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Coupon Type</InputLabel>
                <Select
                  sx={selectFieldStyles}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={couponType}
                  label="Coupon Type"
                  onChange={(e) => {
                    setcouponType(e.target.value);
                  }}
                >
                  <MenuItem value='percentage'>Percentage</MenuItem>
                  <MenuItem value='amount'>Amount (Default)</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <TextField
              sx={style}
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#CB2704"
                  }
                }
              }}
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              onChange={(e) => {
                setcouponName(e.target.value);
              }}
            />
            <TextField
              sx={style}
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#CB2704"
                  }
                }
              }}
              autoFocus
              margin="dense"
              id="name"
              label="Coupon Code"
              type="text"
              fullWidth
              onChange={(e) => {
                setcouponCode(e.target.value);
              }}
            />
            <TextField
              sx={style}
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#CB2704"
                  }
                }
              }}
              autoFocus
              margin="dense"
              id="name"
              label="Amount or Percentage"
              type="number"
              fullWidth
              onChange={(e) => {
                setcouponAmount(e.target.value);
              }}
            />
            <TextField
              sx={style}
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#CB2704"
                  }
                }
              }}
              autoFocus
              margin="dense"
              id="name"
              label="Duration in months"
              type="number"
              fullWidth
              onChange={(e) => {
                setDuration(e.target.value);
              }}
            />
            <TextField
              sx={style}
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#CB2704"
                  }
                }
              }}
              autoFocus
              margin="dense"
              id="name"
              label="Max redemptions"
              type="number"
              fullWidth
              onChange={(e) => {
                setRedemptions(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button className='inmenu-btn' onClick={handleClickClosed}>Cancel</Button>
            <Button className='inmenu-btn' onClick={submitCoupon}>Submit</Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={openV}
          onClose={handleCloseV}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Training Video"}
          </DialogTitle>
          <DialogContent>
            <video width="535" height="360" controls >
              <source src={demoVideo} type="video/mp4" />
            </video>
          </DialogContent>
          <DialogActions>
            <Button className='inmenu-btn' autoFocus onClick={handleCloseV}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog open={FinancialBox} onClose={handleClickClosed}>
          <DialogTitle>Enter Pin</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Set pin to protact you financial data on dashboard.
            </DialogContentText>
            <TextField
              sx={style}
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#CB2704"
                  }
                }
              }}
              autoFocus
              margin="dense"
              id="name"
              label="Pin"
              type="text"
              fullWidth
              onChange={(e) => {
                setPin(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button className='inmenu-btn' onClick={handleClickClosed}>Cancel</Button>
            <Button className='inmenu-btn' onClick={submitPin}>Submit</Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog open={deliveryDisable} onClose={closeDliveryDialogue}>
          <DialogTitle>Disable Delivery</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to disable food delivery for your restaurant?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className='inmenu-btn' onClick={closeDliveryDialogue}>No</Button>
            <Button className='inmenu-btn' onClick={setDliveryOption}>Yes</Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog open={deliveryEnable} onClose={closeDliveryDialogue}>
          <DialogTitle>Enable Delivery</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to enable food delivery for your restaurant?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className='inmenu-btn' onClick={closeDliveryDialogue}>No</Button>
            <Button className='inmenu-btn' onClick={enableDliveryOption}>Yes</Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog open={changeFinancialPinBox} onClose={handleClickClosed}>
          <DialogTitle>Change Pin</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter your old pin and new pin to change. If you forgot your pin please contact us.
            </DialogContentText>
            <TextField
              sx={style}
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#CB2704"
                  }
                }
              }}
              autoFocus
              margin="dense"
              id="name"
              label="Old Pin"
              type="text"
              fullWidth
              onChange={(e) => {
                setOldPin(e.target.value);
              }}
            />
            <TextField
              sx={style}
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#CB2704"
                  }
                }
              }}
              autoFocus
              margin="dense"
              id="name"
              label="New Pin"
              type="text"
              fullWidth
              onChange={(e) => {
                setNewPin(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: '#CB2704' }} onClick={handleClickClosed}>Cancel</Button>
            <Button sx={{ color: '#CB2704' }} onClick={submitChangePin}>Submit</Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog open={openLocation} onClose={handleClickClosed}>
          <DialogTitle>Update Pin Location</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{mb: 3}}>
              Enter longitude and latitude to update restaurant pin location.
            </DialogContentText>
            <InputLabel id="demo-simple-select-label">
              Longitude
            </InputLabel>
            <TextField
              sx={style}
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#CB2704"
                  }
                }
              }}
              autoFocus
              margin="dense"
              id="name"
              value={longitude}
              type="text"
              fullWidth
              onChange={(e) => {
                setLongitude(e.target.value);
              }}
            />
            <InputLabel id="demo-simple-select-label">
              Latitude
            </InputLabel>
            <TextField
              sx={style}
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#CB2704"
                  }
                }
              }}
              margin="dense"
              id="name"
              value={latitude}
              type="text"
              fullWidth
              onChange={(e) => {
                setLatitude(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: '#CB2704' }} onClick={handleClickClosed}>Cancel</Button>
            <Button sx={{ color: '#CB2704' }} onClick={submitLocation}>Submit</Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}