import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { login, loginWithGoogle } from '../../shared/api/user';
import { useNavigate } from "react-router-dom";
import CustomizedSnackbars from '../Snackbar/Snackbar';
import { useState, useEffect } from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import loadImage from '../../shared/images/loading.gif';
import "./SignIn.css";
import { inputLabelClasses } from "@mui/material/InputLabel";
// import { GoogleLogin } from '@react-oauth/google';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import loadingImage from '../../shared/images/slider.jpg'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://forunpoint.com.pk/">
      ForunPoint
      </Link>{' '}
      {new Date().getFullYear()}
      {'. All Rights Reserved.'}
    </Typography>
  );
}
const theme = createTheme();

export default function SignIn() {
  const [error, setError] = useState("");
  const [Loading, setLoading] = useState(true);
  const [user, setUser] = useState([]);
  const [disableSignup, setdisableSignup] = useState(false);
  const navigate = useNavigate();
  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#CB2704"
      }
    }
  }
  const socialLogin = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
});
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let body = {
      email: data.get('email'),
      password: data.get('password'),
    }
    try {
      let res = await login(body);
      if (res.data.user.role === 'CHEF') {
        localStorage.setItem('token', `Bearer ${res.data.token}`);
        navigate("/dashboard");
      } else {
        setError('Not an Restaurant!');
        setTimeout(() => {
          setError('');
        }, 4000);

      }
    } catch (err) {
      setError(err.response.data.status.message);
      setTimeout(() => {
        setError('');
      }, 4000);
    }
  };

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  useEffect(
    () => {
        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then(async (res) => {
                  try {
                    let body = {
                      googleUserId: res.data.id,
                      email: res.data.email
                    };
                    let resp = await loginWithGoogle(body);
                    setdisableSignup(false);
                    if (resp.data.user.role === 'CHEF') {
                      localStorage.setItem('token', `bearer ${resp.data.token}`);
                      navigate("/dashboard");
                    } else {
                      setError('Not an Chef!');
                      setTimeout(() => {
                        setError('');
                      }, 4000);
              
                    }
                  } catch (err) {
                    setError(err?.response.data.status.message);
                    setTimeout(() => {
                      setError('');
                    }, 4000);
                  }
                })
                .catch((err) => console.log(err));
        }
    },
    [user]
);

  if (Loading) {
    return (
      <div className='loading-card'>
        <img className='loading-img' src={loadImage} alt='Loading...'></img>
      </div>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        {error && <CustomizedSnackbars msg={error} isOpen={error !== ""}></CustomizedSnackbars>}
        <Grid container component="main" sx={{ height: '100vh' }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={false}
            md={8}
            sx={{
              backgroundImage: `url(${loadingImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <Grid item xs={12} sm={12} md={4} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'black' }}>
                <DashboardIcon />
              </Avatar>
              <Typography sx={{ mt: 3, mb: 2, fontWeight: '600' }} component="h1" variant="h5">
                Login to Restaurant Dashboard
              </Typography>
              {/* <button disabled={disableSignup} type="button" className="login-with-google-btn" onClick={() => socialLogin()}>Login with Google</button> */}
              <Typography sx={{ fontWeight: '600' }} variant="body2" color="text.secondary" align="center">
                {`Don't have an account? `}
                <Link color="inherit" href="/signup">
                  Join as Restaurant
                </Link>
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  sx={style}
                  InputLabelProps={{
                    sx: {
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#CB2704"
                      }
                    }
                  }}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  sx={style}
                  InputLabelProps={{
                    sx: {
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#CB2704"
                      }
                    }
                  }}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Typography sx={{ fontWeight: '600' }} variant="body2" color="text.secondary">
                  {`Forgot your password? `}
                  <Link color="inherit" href="/forgot">
                    Reset
                  </Link>
                </Typography>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, background: '#CB2704', "&:hover": { background: "red" } }}
                >
                  Login
                </Button>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}
