import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { signup, getCuisine } from '../../shared/api/user';
import { useNavigate } from "react-router-dom";
import CustomizedSnackbars from '../Snackbar/Snackbar';
import { useState, useEffect } from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import loadImage from '../../shared/images/loading.gif';
import "./Signup.css";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { inputLabelClasses } from "@mui/material/InputLabel";
import { GoogleLogin } from '@react-oauth/google';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { MultiSelect } from "react-multi-select-component";
import loadingImage from '../../shared/images/slider.jpg'

const options = [
    { label: "Breakfast", value: "breakfast" },
    { label: "Lunch", value: "Lunch" },
    { label: "Dinner", value: "Dinner" },
    { label: "Fastfood", value: "Fastfood" },
    { label: "Pizza", value: "Pizza" },
    { label: "Steakhouse", value: "Steakhouse" },
    { label: "Seafood", value: "Seafood" },
    { label: "Halal", value: "Halal" },
    { label: "Kosher", value: "Kosher" },
    { label: "Veggies", value: "Veggies" },
    { label: "Vegan", value: "Vegan" },
    { label: "Donuts", value: "Donuts" },
    { label: "Bakery", value: "Bakery" },
];


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://forunpoint.com.pk/">
            ForunPoint
            </Link>{' '}
            {new Date().getFullYear()}
            {'. All Rights Reserved.'}
        </Typography>
    );
}
const theme = createTheme();

export default function SignIn() {
    const [error, setError] = useState("");
    const [Loading, setLoading] = useState(true);
    const [type, setType] = React.useState('');
    const [Picture, setPicture] = React.useState('');
    const [googleUserId, setgoogleUserId] = React.useState('');
    const [user, setUser] = useState([]);
    const [profile, setProfile] = useState([]);
    const [disableSignup, setdisableSignup] = useState(false);
    const [selected, setSelected] = useState([]);
    const [RestaurantType, setRestaurantType] = useState(false);
    const [Cuisines, setCuisines] = useState([]);
    const [cuisineId, setCuisineId] = React.useState('');
    const navigate = useNavigate();

    const style = {
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "#CB2704"
            }
        }
    }

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    const selectFieldStyles = {
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CB2704",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CB2704",
        },
    };

    const init = async () => {
        let cuisine = await getCuisine();
        setCuisines(cuisine.data.cuisines);
    }

    useEffect(() => {
        init();
    }, []);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let body = {
            email: data.get('email'),
            password: data.get('password'),
            firstName: data.get('firstName'),
            lastName: data.get('lastName'),
            phoneNumber: data.get('phoneNumber'),
            restaurantName: data.get('restaurantName'),
            chefType: type,
            role: 'CHEF',
            deviceType: "Android",
            restaurantTypes: selected,
            cuisine: cuisineId,
        }
        if (Picture || googleUserId) {
            body.picture = Picture;
            body.googleUserId = googleUserId;
            body.isEmailVerified = true;
        }
        try {
            let res = await signup(body);
            if (res.data.user.role === 'CHEF') {
                localStorage.setItem('token', `bearer ${res.data.token}`);
                navigate("/dashboard");
            } else {
                setError('Not an Chef!');
                setTimeout(() => {
                    setError('');
                }, 4000);

            }
        } catch (err) {
            setError(err.response.data.status.message);
            setTimeout(() => {
                setError('');
            }, 4000);
        }
    };

    setTimeout(() => {
        setLoading(false);
    }, 1000);

    const handleChange = (event) => {
        setType(event.target.value);
        if (event.target.value === 'RESTAURANT') {
            setRestaurantType(true);
        } else {
            setRestaurantType(false);
        }
    };

    const handleCuisine = (event) => {
        setCuisineId(event.target.value);
    };

    useEffect(
        () => {
            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        setProfile(res.data);
                        var email = document.getElementById('email');
                        email.value = res.data.email;
                        var first = document.getElementById('firstName');
                        first.value = res.data.given_name;
                        var last = document.getElementById('lastName');
                        last.value = res.data.family_name;
                        setPicture(res.data.picture);
                        setgoogleUserId(res.data.id);
                        setdisableSignup(true);
                    })
                    .catch((err) => console.log(err));
            }
        },
        [user]
    );

    if (Loading) {
        return (
            <div className='loading-card'>
                <img className='loading-img' src={loadImage} alt='Loading...'></img>
            </div>
        );
    } else {
        return (
            <ThemeProvider theme={theme}>
                {error && <CustomizedSnackbars msg={error} isOpen={error !== ""}></CustomizedSnackbars>}
                <Grid container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={false}
                        md={8}
                        sx={{
                            backgroundImage: `url(${loadingImage})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />
                    <Grid item xs={12} sm={12} md={4} component={Paper} elevation={6} square>
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'black' }}>
                                <DashboardIcon />
                            </Avatar>
                            <Typography sx={{ mt: 3, mb: 2, fontWeight: '600' }} component="h1" variant="h5">
                                Join as Restaurant
                            </Typography>
                            <button disabled={disableSignup} type="button" className="login-with-google-btn" onClick={() => login()}>Signup with Google</button>
                            <Typography sx={{ fontWeight: '600' }} variant="body2" color="text.secondary" align="center">
                                {'Already have an account? '}
                                <Link color="inherit" href="/">
                                    Login
                                </Link>
                            </Typography>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                <InputLabel id="Choose-Type">Choose Type</InputLabel>
                                <Select
                                    sx={selectFieldStyles}
                                    labelId="Choose-Type"
                                    id="Choose-Type"
                                    value={type}
                                    label="Choose Type"
                                    onChange={handleChange}
                                >
                                    <MenuItem value='PROFESSIONAL_CHEF'>Professional Chef</MenuItem>
                                    <MenuItem value='RESTAURANT'>Restaurant</MenuItem>
                                </Select>
                                {!RestaurantType &&
                                    <Box sx={{ display: 'flex', mb: 1, mt: 3, justifyContent: 'space-between' }}>
                                        <Grid item xs={11} sm={5}>
                                            <TextField
                                                sx={style}
                                                InputLabelProps={{
                                                    sx: {
                                                        [`&.${inputLabelClasses.shrink}`]: {
                                                            color: "#CB2704"
                                                        }
                                                    }
                                                }}
                                                autoComplete="given-name"
                                                name="firstName"
                                                required
                                                placeholder='First Name'
                                                fullWidth
                                                id="firstName"
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item xs={11} sm={5}>
                                            <TextField
                                                sx={style}
                                                InputLabelProps={{
                                                    sx: {
                                                        [`&.${inputLabelClasses.shrink}`]: {
                                                            color: "#CB2704"
                                                        }
                                                    }
                                                }}
                                                fullWidth
                                                required
                                                placeholder="Last Name"
                                                id="lastName"
                                                name="lastName"
                                                autoComplete="family-name"
                                            />
                                        </Grid>
                                    </Box>
                                }
                                {RestaurantType &&
                                    <TextField
                                        sx={style}
                                        InputLabelProps={{
                                            sx: {
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    color: "#CB2704"
                                                }
                                            }
                                        }}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="restaurantName"
                                        placeholder="Restaurant Name"
                                        name="restaurantName"
                                        autoFocus
                                    />
                                }

                                <TextField
                                    sx={style}
                                    InputLabelProps={{
                                        sx: {
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "#CB2704"
                                            }
                                        }
                                    }}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    placeholder="Email Address"
                                    name="email"
                                    autoComplete="email"
                                />
                                <TextField
                                    sx={style}
                                    InputLabelProps={{
                                        sx: {
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "#CB2704"
                                            }
                                        }
                                    }}
                                    type="number"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="phoneNumber"
                                    placeholder="Phone Number*"
                                    name="phoneNumber"
                                    autoComplete=""
                                />
                                <TextField
                                    sx={style}
                                    InputLabelProps={{
                                        sx: {
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "#CB2704"
                                            }
                                        }
                                    }}
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    placeholder="Password*"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                />
                                <InputLabel id="Choose-Type">Choose Cuisine</InputLabel>
                                <Select
                                    sx={selectFieldStyles}
                                    labelId="Choose-Cuisine"
                                    id="Choose-Cuisine"
                                    value={cuisineId}
                                    label="Choose Cuisine"
                                    onChange={handleCuisine}
                                >
                                    {Cuisines.map((Cuisine) => (
                                        <MenuItem value={Cuisine.title}>
                                            {Cuisine.title}
                                        </MenuItem>
                                    ))}

                                </Select>
                                <MultiSelect
                                    options={options}
                                    value={selected}
                                    hasSelectAll={false}
                                    onChange={setSelected}
                                    labelledBy="Food types"
                                />
                                <Box sx={{ display: 'flex', mb: 1, mt: 4, justifyContent: 'space-between' }}>
                                    <Typography sx={{ fontWeight: 600 }} variant="body2" color="text.secondary">
                                        {'By clicking signup, I state that I have read and understood the '}
                                        <Link color="inherit" href="https://forunpoint.com.pk/privacy-policy">
                                            Privacy Policy
                                        </Link>
                                        {' and '}
                                        <Link color="inherit" href="https://forunpoint.com.pk/terms">
                                            Terms & Conditions.
                                        </Link>
                                    </Typography>
                                </Box>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2, fontWeight: '600', background: '#CB2704', "&:hover": { background: "red" } }}
                                >
                                    Signup
                                </Button>
                                <Copyright sx={{ mt: 5 }} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        );
    }
}
