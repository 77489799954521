import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "./dishadd.css";
import { getCategory } from "../../shared/api/user";
import { getCuisine, getMe } from "../../shared/api/user";
import { getDietary, createCoupon } from "../../shared/api/user";
import { Formik, Form } from "formik";
import { uploadImage } from "../../shared/api/user";
import { addDish } from "../../shared/api/user";
import CustomizedSnackbars from "../Snackbar/Snackbar";
import { useNavigate } from "react-router-dom";
import DialogContentText from "@mui/material/DialogContentText";
import FormControl from "@mui/material/FormControl";
import { inputLabelClasses } from "@mui/material/InputLabel";

const theme = createTheme();
export default function SignUp() {
  const [open, setOpen] = React.useState(false);
  const [openDeal, setOpenDeal] = React.useState(false);
  const [openSize, setOpenSize] = React.useState(false);
  const [openSauce, setOpenSauce] = React.useState(false);
  const [openCrust, setOpenCrust] = React.useState(false);
  const [openFlavour, setOpenFlavour] = React.useState(false);
  const [disablePrice, setDisablePrice] = React.useState(false);
  const [Category, setCategory] = React.useState([]);
  const [Cuisine, setCuisine] = React.useState([]);
  const [Dietary, setDietary] = React.useState([]);
  const [File, setFile] = React.useState();
  const [Addon, setAddon] = React.useState([]);
  const [Size, setSize] = React.useState([]);
  const [freeDrinkOption, setFreeDrinkOption] = React.useState([]);
  const [flavours, setFlavours] = React.useState('');
  const [crust, setCrust] = React.useState('');
  const [sauces, setSauces] = React.useState('');
  const [submitSauces, setSubmitSauces] = React.useState([]);
  const [submitCrusts, setSubmitCrusts] = React.useState([]);
  const [submitFlavours, setSubmitFlavours] = React.useState([]);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [opened, setOpened] = React.useState(false);
  const [couponName, setcouponName] = useState("");
  const [couponCode, setcouponCode] = useState("");
  const [couponAmount, setcouponAmount] = useState("");
  const [couponType, setcouponType] = useState("");
  const [Duration, setDuration] = useState("");
  const [Redemptions, setRedemptions] = useState("");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#CB2704",
      },
    },
  };

  const selectFieldStyles = {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CB2704",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CB2704",
    },
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenDeal = () => {
    setOpenDeal(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDeal = () => {
    setOpenDeal(false);
  };

  const handleClickOpenSize = () => {
    setOpenSize(true);
  };

  const handleCloseSize = () => {
    setOpenSize(false);
  };

  const handleClickOpenSauce = () => {
    setOpenSauce(true);
  };

  const handleCloseSauce = () => {
    setOpenSauce(false);
  };

  const handleClickOpenCrust = () => {
    setOpenCrust(true);
  };

  const handleCloseCrust = () => {
    setOpenCrust(false);
  };

  const handleSauceAdd = (e) => {
    setSauces(e);
  };

  const submitSauce = () => {
    submitSauces.push(sauces)
    setSubmitSauces(submitSauces);
    setOpenSauce(false)
  }

  const handleCrustAdd = (e) => {
    setCrust(e);
  };

  const submitCrust = () => {
    submitCrusts.push(crust)
    setSubmitCrusts(submitCrusts);
    setOpenCrust(false)
  }

  const handleClickOpenFlavour = () => {
    setOpenFlavour(true);
  };

  const handleCloseFlavour = () => {
    setOpenFlavour(false);
  };

  const handleFlavourAdd = (e) => {
    setFlavours(e);
  };

  const submitFlavour = () => {
    submitFlavours.push(flavours)
    setSubmitFlavours(submitFlavours);
    setOpenFlavour(false)
  }

  const handleFile = async (event) => {
    setFile(event.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedImage(reader.result);
    };
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleClickOpened = () => {
    setOpened(true);
  };

  const handleClickClosed = () => {
    setOpened(false);
  };

  const submitCoupon = async () => {
    let code = couponCode.toUpperCase();
    setcouponCode(code);
    let body = {
      name: couponName,
      duration_in_months: Duration,
      code: code,
      max_redemptions: Redemptions,
    };
    if (couponType === "percentage") {
      body.percent_off = couponAmount;
    } else {
      let finalAmount = (couponAmount * 100).toFixed(0);
      body.amount_off = finalAmount;
      body.currency = "USD";
    }
    await createCoupon(body);
    setOpened(false);
  };

  const init = async () => {
    let user = await getMe();
    let dietaries = await getDietary();
    let cuisine = await getCuisine();
    setUser(user.data.user);
    setCategory(user.data.user.categories);
    setCuisine(cuisine.data.cuisines);
    setDietary(dietaries.data.dietaries);
  };
  useEffect(() => {
    init();
  }, []);

  const hasSpaceOrSpecialChars = (str) => {
    const regex = /^[ ]|[$%^&*#@|'"<>?`~]/;
    return regex.test(str);
  };

  const isFirstLetterCapitalized = (str) => /^[A-Z]/.test(str);

  return (
    <ThemeProvider theme={theme}>
      {error && (
        <CustomizedSnackbars
          msg={error}
          isOpen={error !== ""}
        ></CustomizedSnackbars>
      )}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ mb: 2, fontWeight: "600" }}
            component="h1"
            variant="h5"
          >
            Add New Dish
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Formik
                initialValues={{
                  dishName: "",
                  description: "",
                  price: "",
                  fullPrice: "",
                  category: "",
                  cuisine: "",
                  dietary: "",
                  offers: "",
                  picture: "",
                  addons: [],
                  sizes: [],
                  flavours: [],
                  crust: [],
                  sauces: [],
                  status: "PENDING",
                  coupon: "",
                  estCookingTime: 0,
                  location: {},
                  freeDrinkOptions: []
                }}
                onSubmit={(values, { setSubmitting }) => {
                  if (hasSpaceOrSpecialChars(values.dishName)) {
                    setMessage('Space or special characters not allowed!')
                    setSubmitting(false);
                  } else if (hasSpaceOrSpecialChars(values.description)) {
                    setMessage('Space or special characters not allowed!')
                    setSubmitting(false);
                  } else if (!isFirstLetterCapitalized(values.dishName)) {
                    setMessage('First letter must be capital!')
                    setSubmitting(false);
                  } else if (!isFirstLetterCapitalized(values.description)) {
                    setMessage('First letter must be capital!')
                    setSubmitting(false);
                  } else {
                    setMessage('');
                    setTimeout(async () => {
                      if (File) {
                        const formData = new FormData();
                        formData.append("file", File);
                        let res = await uploadImage(formData);
                        values.picture = res.data.data.url;
                      }
                      if (Addon) {
                        values.addons = Addon;
                      }
                      if (Size) {
                        values.sizes = Size;
                      }
                      if (couponCode) {
                        values.coupon = couponCode;
                      }
                      if (submitSauces) {
                        values.sauces = submitSauces;
                      }
                      if (submitCrusts) {
                        values.crust = submitCrusts;
                      }
                      if (submitFlavours) {
                        values.flavours = submitFlavours;
                      }
                      if (freeDrinkOption) {
                        values.freeDrinkOptions = freeDrinkOption;
                      }
                      values.location = user.location;
                      values.price = parseFloat(values.price);

                      if (!values.fullPrice || values.fullPrice === "" || values.fullPrice === 0) {
                        values.fullPrice = values.price;
                      }
                      await addDish(values);
                      setSubmitting(false);
                      setError("Dish added!");
                      navigate("/dishes");
                    }, 400);
                  }
                }}
              >
                {({ handleSubmit, handleChange, isSubmitting, values }) => (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <Grid mb={3} item xs={12}>
                      <TextField
                        sx={style}
                        InputLabelProps={{
                          sx: {
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#CB2704",
                            },
                          },
                        }}
                        fullWidth
                        id="dishName"
                        label="Dish Name"
                        name="dishName"
                        autoComplete="text"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid mb={3} item xs={12}>
                      <TextField
                        sx={style}
                        InputLabelProps={{
                          sx: {
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#CB2704",
                            },
                          },
                        }}
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        autoComplete="text"
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        sx={style}
                        InputLabelProps={{
                          sx: {
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#CB2704",
                            },
                          },
                        }}
                        fullWidth
                        id="estCookingTime"
                        label="Estimated Cooking time in minutes"
                        name="estCookingTime"
                        type="text"
                        autoComplete="text"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel id="demo-simple-select-label">
                        Category
                      </InputLabel>
                      <Select
                        sx={selectFieldStyles}
                        labelId="demo-simple-select-label"
                        id="category"
                        name="category"
                        value={values.category}
                        label="Category"
                        onChange={handleChange}
                      >
                        {Category.map((Category) => (
                          <MenuItem id={Category} value={Category}>
                            {Category}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel id="demo-simple-select-label">
                        Cuisine
                      </InputLabel>
                      <Select
                        sx={selectFieldStyles}
                        labelId="demo-simple-select-label"
                        id="cuisine"
                        name="cuisine"
                        value={values.cuisine}
                        label="Cuisine"
                        onChange={handleChange}
                      >
                        {Cuisine.map((Cuisine) => (
                          <MenuItem id={Cuisine._id} value={Cuisine._id}>
                            {Cuisine.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel id="demo-simple-select-label">
                        Dietary
                      </InputLabel>
                      <Select
                        sx={selectFieldStyles}
                        labelId="demo-simple-select-label"
                        id="dietary"
                        name="dietary"
                        value={values.dietary}
                        label="Dietary"
                        onChange={handleChange}
                      >
                        {Dietary.map((Dietary) => (
                          <MenuItem id={Dietary._id} value={Dietary._id}>
                            {Dietary.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel id="demo-simple-select-label">
                        Offers
                      </InputLabel>
                      <Select
                        sx={selectFieldStyles}
                        labelId="demo-simple-select-label"
                        id="offers"
                        name="offers"
                        value={values.offers}
                        label="Offers"
                        onChange={handleChange}
                      >
                        <MenuItem value="BUY_ONE_GET_ONE_FREE">
                          Buy One get one free
                        </MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel id="demo-simple-select-label">
                        Full Price
                      </InputLabel>
                      <TextField
                        sx={style}
                        InputLabelProps={{
                          sx: {
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#CB2704",
                            },
                          },
                        }}
                        id="fullPrice"
                        label={process.env.REACT_APP_CURRENCY}
                        name="fullPrice"
                        type="text"
                        autoComplete="text"
                        onChange={handleChange}
                        disabled={disablePrice}
                      />
                    </Grid>
                    <Box sx={{ display: 'flex' }}>
                      <Grid item xs={12}>
                        <InputLabel id="demo-simple-select-label">
                          Discounted Price
                        </InputLabel>
                        <TextField
                          sx={style}
                          InputLabelProps={{
                            sx: {
                              [`&.${inputLabelClasses.shrink}`]: {
                                color: "#CB2704",
                              },
                            },
                          }}
                          id="price"
                          label={process.env.REACT_APP_CURRENCY}
                          name="price"
                          type="text"
                          autoComplete="text"
                          onChange={handleChange}
                          disabled={disablePrice}
                        />
                      </Grid>
                      <Button onClick={handleClickOpenSize} sx={{ border: "1px solid #CB2704", width: 200, height: 55, marginTop: 3, color: "#CB2704", "&:hover": { background: "#CB2704", color: 'white' }, }}>Add Size</Button>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button onClick={handleClickOpenCrust} sx={{ border: "1px solid #CB2704", width: 200, height: 40, marginTop: 3, color: "#CB2704", "&:hover": { background: "#CB2704", color: 'white' }, }}>Add Crust Option</Button>
                      <Button onClick={handleClickOpenSauce} sx={{ border: "1px solid #CB2704", width: 200, height: 40, marginTop: 3, color: "#CB2704", "&:hover": { background: "#CB2704", color: 'white' }, }}>Add Sauces Option</Button>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button onClick={handleClickOpenFlavour} sx={{ border: "1px solid #CB2704", width: 200, height: 40, marginTop: 3, color: "#CB2704", "&:hover": { background: "#CB2704", color: 'white' }, }}>Add Flavour Option</Button>
                      <Button onClick={handleClickOpen} sx={{ border: "1px solid #CB2704", width: 200, height: 40, marginTop: 3, color: "#CB2704", "&:hover": { background: "#CB2704", color: 'white' }, }}>Add Addons</Button>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button onClick={handleClickOpenDeal} sx={{ border: "1px solid #CB2704", width: 200, height: 40, marginTop: 3, color: "#CB2704", "&:hover": { background: "#CB2704", color: 'white' }, }}>Add Free Deal Items</Button>
                    </Box>
                    {Addon.length > 0 && (
                      <Box sx={{ mt: 2 }}>
                        <div style={{ marginTop: '10px', fontWeight: 600 }}>Addons</div>
                        {Addon.map((Adon) => (
                          <Box
                            sx={{
                              mt: 1,
                              display: "flex",
                              justifyContent: "space-between",
                              pl: 1,
                              pr: 1,
                            }}
                          >
                            <div>{Adon.addonName}</div>
                            <div>{process.env.REACT_APP_CURRENCY}{Adon.addonPrice}</div>
                          </Box>
                        ))}
                      </Box>
                    )}

                    {Size.length > 0 && (
                      <Box sx={{ mt: 1 }}>
                        <div style={{ marginTop: '10px', fontWeight: 600 }}>Sizes</div>
                        {Size.map((size) => (
                          <Box
                            sx={{
                              mt: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              pl: 1,
                              pr: 1,
                            }}
                          >
                            <div>{size.size}</div>
                            <div>{process.env.REACT_APP_CURRENCY}{size.price}</div>
                          </Box>
                        ))}
                      </Box>
                    )}

                    {submitSauces.length > 0 && (
                      <Box sx={{ mt: 1 }}>
                        <div style={{ marginTop: '10px', fontWeight: 600 }}>Sauces</div>
                        {submitSauces.map((sauce) => (
                          <Box
                            sx={{
                              mt: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              pl: 1,
                              pr: 1,
                            }}
                          >
                            <div>{sauce}</div>
                          </Box>
                        ))}
                      </Box>
                    )}

                    {submitCrusts.length > 0 && (
                      <Box sx={{ mt: 1 }}>
                        <div style={{ marginTop: '10px', fontWeight: 600 }}>Crust</div>
                        {submitCrusts.map((crust) => (
                          <Box
                            sx={{
                              mt: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              pl: 1,
                              pr: 1,
                            }}
                          >
                            <div>{crust}</div>
                          </Box>
                        ))}
                      </Box>
                    )}


                    {submitFlavours.length > 0 && (
                      <Box sx={{ mt: 1 }}>
                        <div style={{ marginTop: '10px', fontWeight: 600 }}>Flavours</div>
                        {submitFlavours.map((flavour) => (
                          <Box
                            sx={{
                              mt: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              pl: 1,
                              pr: 1,
                            }}
                          >
                            <div>{flavour}</div>
                          </Box>
                        ))}
                      </Box>
                    )}

                    {freeDrinkOption.length > 0 && (
                      <Box sx={{ mt: 1 }}>
                        <div style={{ marginTop: '10px', fontWeight: 600 }}>Free Options</div>
                        {freeDrinkOption.map((item) => (
                          <Box
                            sx={{
                              mt: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              pl: 1,
                              pr: 1,
                            }}
                          >
                            <div>{item.name}</div>
                            <div>{item.size}</div>
                          </Box>
                        ))}
                      </Box>
                    )}

                    {message && <div style={{ textAlign: "center", marginTop: "15px", color: '#CB2704' }}>{message}</div>}

                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                      {uploadedImage && (
                        <img
                          src={uploadedImage}
                          alt="Uploaded"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "200px",
                            borderRadius: "10px",
                          }}
                        />
                      )}
                    </div>

                    <Button
                      variant="contained"
                      component="label"
                      fullWidth
                      sx={{
                        mt: 3,
                        mb: 1,
                        background: "#CB2704",
                        "&:hover": { background: "red" },
                      }}
                    >
                      Upload Image
                      <input type="file" onChange={handleFile} hidden />
                    </Button>
                    {/* <Button
                      onClick={handleClickOpen}
                      variant="contained"
                      component="label"
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 1,
                        background: "#CB2704",
                        "&:hover": { background: "red" },
                      }}
                    >
                      Addons (Optional)
                    </Button> */}
                    <Button
                      onClick={handleClickOpened}
                      variant="contained"
                      component="label"
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 1,
                        background: "#CB2704",
                        "&:hover": { background: "red" },
                      }}
                    >
                      Coupon
                    </Button>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 1,
                        mb: 2,
                        background: "#CB2704",
                        "&:hover": { background: "red" },
                      }}
                    >
                      Submit
                    </Button>
                  </form>
                )}
              </Formik>
            </Grid>

            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Add Addon</DialogTitle>
              <DialogContent>
                <Formik
                  initialValues={{ addonName: "", addonPrice: 0 }}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      values.addonPrice = parseFloat(values.addonPrice);
                      Addon.push(values);
                      setAddon(Addon);
                      setSubmitting(false);
                      setOpen(false);
                    }, 400);
                  }}
                >
                  {({ handleSubmit, handleChange, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="addonName"
                        name="addonName"
                        label="Addon name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChange}
                      />
                      <TextField
                        margin="dense"
                        id="addonPrice"
                        name="addonPrice"
                        label="Price"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChange}
                      />

                      <DialogActions>
                        <Button sx={{ color: '#CB2704' }} onClick={handleClose}>Cancel</Button>
                        <Button sx={{ color: '#CB2704' }} type="submit" disabled={isSubmitting}>
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  )}
                </Formik>
              </DialogContent>
            </Dialog>

            <Dialog open={openSize} onClose={handleCloseSize}>
              <DialogTitle>Add Size</DialogTitle>
              <DialogContent>
                <Formik
                  initialValues={{ size: "", price: 0, fullPrice: 0}}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      values.price = parseFloat(values.price);
                      if (!values.fullPrice || values.fullPrice === "" || values.fullPrice === 0) {
                        values.fullPrice = values.price;
                      }
                      Size.push(values);
                      setSize(Size);
                      setSubmitting(false);
                      setOpenSize(false);
                      setDisablePrice(true);
                    }, 400);
                  }}
                >
                  {({ handleSubmit, handleChange, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="size"
                        name="size"
                        label="Size"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChange}
                      />
                      <TextField
                        margin="dense"
                        id="price"
                        name="price"
                        label="Discounted Price"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChange}
                      />
                      <TextField
                        margin="dense"
                        id="fullPrice"
                        name="fullPrice"
                        label="Full Price"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChange}
                      />

                      <DialogActions>
                        <Button sx={{ color: '#CB2704' }} onClick={handleCloseSize}>Cancel</Button>
                        <Button sx={{ color: '#CB2704' }} type="submit" disabled={isSubmitting}>
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  )}
                </Formik>
              </DialogContent>
            </Dialog>

            <Dialog open={openDeal} onClose={handleCloseDeal}>
              <DialogTitle>Add Free Options</DialogTitle>
              <DialogContent>
                <Formik
                  initialValues={{ name: "", size: "" }}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      freeDrinkOption.push(values);
                      setFreeDrinkOption(freeDrinkOption);
                      setSubmitting(false);
                      setOpenDeal(false);
                      setDisablePrice(true);
                    }, 400);
                  }}
                >
                  {({ handleSubmit, handleChange, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChange}
                      />
                      <TextField
                        margin="dense"
                        id="size"
                        name="size"
                        label="Size"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChange}
                      />

                      <DialogActions>
                        <Button sx={{ color: '#CB2704' }} onClick={handleCloseDeal}>Cancel</Button>
                        <Button sx={{ color: '#CB2704' }} type="submit" disabled={isSubmitting}>
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  )}
                </Formik>
              </DialogContent>
            </Dialog>

            <Dialog open={openSauce} onClose={handleCloseSauce}>
              <DialogTitle>Add Sauce Option</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="sauce"
                  name="sauce"
                  label="Sauce name"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    handleSauceAdd(e.target.value);
                  }}
                />

                <DialogActions>
                  <Button sx={{ color: '#CB2704' }} onClick={handleCloseSauce}>Cancel</Button>
                  <Button sx={{ color: '#CB2704' }} onClick={submitSauce} type="submit">
                    Submit
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>

            <Dialog open={openCrust} onClose={handleCloseCrust}>
              <DialogTitle>Add Crust Option</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="crust"
                  name="crust"
                  label="Crust name"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    handleCrustAdd(e.target.value);
                  }}
                />

                <DialogActions>
                  <Button sx={{ color: '#CB2704' }} onClick={handleCloseCrust}>Cancel</Button>
                  <Button sx={{ color: '#CB2704' }} onClick={submitCrust} type="submit">
                    Submit
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>

            <Dialog open={openFlavour} onClose={handleCloseFlavour}>
              <DialogTitle>Add Flavour Option</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="flavour"
                  name="flavour"
                  label="Flavour name"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    handleFlavourAdd(e.target.value);
                  }}
                />

                <DialogActions>
                  <Button sx={{ color: '#CB2704' }} onClick={handleCloseFlavour}>Cancel</Button>
                  <Button sx={{ color: '#CB2704' }} onClick={submitFlavour} type="submit">
                    Submit
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>

            <div>
              <Dialog open={opened} onClose={handleClickClosed}>
                <DialogTitle>Add Coupon</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Add Chef/Restaurent discount coupon for menu.
                  </DialogContentText>
                  <Box sx={{ minWidth: 120, mt: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Coupon Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={couponType}
                        label="Coupon Type"
                        onChange={(e) => {
                          setcouponType(e.target.value);
                        }}
                      >
                        <MenuItem value="percentage">Percentage</MenuItem>
                        <MenuItem value="amount">Amount (Default)</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      setcouponName(e.target.value);
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Coupon Code"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      setcouponCode(e.target.value);
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Amount or Percentage"
                    type="number"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      setcouponAmount(e.target.value);
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Duration in months"
                    type="number"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      setDuration(e.target.value);
                    }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Max redemptions"
                    type="number"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      setRedemptions(e.target.value);
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button sx={{ color: '#CB2704' }} onClick={handleClickClosed}>Cancel</Button>
                  <Button sx={{ color: '#CB2704' }} onClick={submitCoupon}>Submit</Button>
                </DialogActions>
              </Dialog>
            </div>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
