import * as React from 'react';
import "../Financial/Financial.css";
import { getMe, getProfitDetails } from '../../shared/api/user';
import { useState, useEffect } from 'react';
import { getOrdersList, getCoupon, deleteCoupon, updateUser } from '../../shared/api/user';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PaidIcon from '@mui/icons-material/Paid';
import Button from '@mui/material/Button';
import ConstructionIcon from '@mui/icons-material/Construction';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import CustomizedSnackbars from '../Snackbar/Snackbar';
import Reports from '../Reports/Reports';

function preventDefault(event) {
  event.preventDefault();
}

function Financial() {
  const [profit, setprofit] = useState([]);
  const [orders, setOrders] = useState([]);
  const [coupon, setCoupon] = useState([]);
  const [User, setUser] = useState([]);
  const [financialPin, setFinancialPin] = useState(true);
  const [Pin, setPin] = useState('');
  const [UserPin, setUserPin] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const init = async () => {
    let prof = await getProfitDetails();
    setprofit(prof.data);
    let rows = await getOrdersList(0, 100);
    let user = await getMe();
    setUser(user.data.user);
    let data = rows.data.orders.filter(function (e) {
      return e.orderStatus === 'COMPLETED';
    });
    if (user.data.user.financialPin) {
      setPin(user.data.user.financialPin)
    } else {
      setFinancialPin(false)
    }
    let Coupon = await getCoupon(user.data.user.restaurantCoupon);
    setCoupon(Coupon)
    setOrders(data);
  }

  const handleClickClosed = () => {
    navigate("/dashboard");
  };

  const submitPin = async (e) => {
    if (UserPin === Pin) {
      setFinancialPin(false);
    } else {
      setError('Wrong Pin!');
      setTimeout(() => {
        setError('');
      }, 4000);
    }
  };

  const deleteCouponCode = async () => {
    let del = await deleteCoupon(coupon?.coupon?.data[0]?.coupon?.id);
    await updateUser(User._id, { restaurantCoupon: '' });
    window.location.reload();
  };

  useEffect(() => {
    init();
  }, []);
  return (
    <div>
      {error && <CustomizedSnackbars msg={error} isOpen={error !== ""}></CustomizedSnackbars>}
      {financialPin && <div>
        <div>
          <Dialog open={financialPin} onClose={handleClickClosed}>
            <DialogTitle>Enter Pin</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Enter your pin to continue...
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Pin"
                type="text"
                fullWidth
                variant="standard"
                onChange={(e) => {
                  setUserPin(e.target.value);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button className='inmenu-btn' onClick={handleClickClosed}>Cancel</Button>
              <Button className='inmenu-btn' onClick={submitPin}>Submit</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>}
      {!financialPin && <div>
        <Button onClick={() => window.location.href = '/dish'} sx={{ mb: 2, color: '#CB2704', border: '1px solid #CB2704', "&:hover": { border: "1px solid red" } }} variant="outlined">
          Stripe Connect Dashboard
        </Button>
        <Reports/>
        <div>
          <div className='financial-cards'>
            {/* <div className='finance-card'>
              <div className='orders-card'>
                <div>
                  <div className='sale-title'>Sales Today</div>
                  <div className='sale-data'>${profit?.todaysSale?.toFixed(2)}</div>
                  <div className='sale-title'>Profit Today</div>
                  <div className='sale-data'>{profit?.todaysProfit?.toFixed(2)}</div>
                </div>
                <div className='cart-icon'><PaidIcon fontSize="large" /></div>
              </div>
            </div> */}
            <div className='finance-card'>
              <div className='orders-card'>
                <div>
                  <div className='sale-title'>Weekly Sales</div>
                  <div className='sale-data'>{process.env.REACT_APP_CURRENCY}{profit?.weeklySale?.toFixed(2)}</div>
                  <div className='sale-title'>Weekly Profit</div>
                  <div className='sale-data'>{profit?.weeklyProfit?.toFixed(2)}</div>
                </div>
                <div className='cart-icon'><PaidIcon fontSize="large" /></div>
              </div>
            </div>
            <div className='finance-card'>
              <div className='orders-card'>
                <div>
                  <div className='sale-title'>Yearly Sales</div>
                  <div className='sale-data'>{process.env.REACT_APP_CURRENCY}{profit?.yearlySale?.toFixed(2)}</div>
                  <div className='sale-title'>Yearly Profit</div>
                  <div className='sale-data'>{profit?.yearlyProfit?.toFixed(2)}</div>
                </div>
                <div className='cart-icon'><PaidIcon fontSize="large" /></div>
              </div>
            </div>
            <div className='finance-card'>
              <div className='orders-card'>
                <div>
                  <div className='sale-title'>Completed Orders</div>
                  <div className='sale-data'>{orders?.length}</div>
                </div>
                <div className='cart-icon'><ShoppingCartIcon fontSize="large" /></div>
              </div>
            </div>
          </div>
        </div>
        {User.restaurantCoupon && <div className='coupons-card'>
          <div className='orders-card'>
            <div>
              <div className='sale-title'>Coupons</div>
              <div className='coupon'>
                <div className='coupon-card'>
                  <div className='sale-data'>{User.restaurantCoupon}</div>
                  <div>{process.env.REACT_APP_CURRENCY}{coupon?.coupon?.data[0]?.coupon?.amount_off / 100}</div>
                </div>
                <div className='del-icon'><DeleteIcon onClick={deleteCouponCode} fontSize="small" /></div>
              </div>
            </div>
          </div>
        </div>}
        {/* <div className='construction'>
          <div className='icon-cont'><ConstructionIcon fontSize="small" /></div>
          <div className='info-text'>This page is under development.</div>
        </div> */}
      </div>}

    </div>
  );
}

export default function Financials() {
  return <Financial />;
}