import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { login, forgotPassword, changePassword } from '../../shared/api/user';
import { useNavigate } from "react-router-dom";
import CustomizedSnackbars from '../Snackbar/Snackbar';
import { useState } from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import loadImage from '../../shared/images/loading.gif';
import "./forgot-password.css";
import { inputLabelClasses } from "@mui/material/InputLabel";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import loadingImage from '../../shared/images/slider.jpg'

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://forunpoint.com/">
            ForunPoint
            </Link>{' '}
            {new Date().getFullYear()}
            {'. All Rights Reserved.'}
        </Typography>
    );
}
const theme = createTheme();

export default function SignIn() {
    const [error, setError] = useState("");
    const [Loading, setLoading] = useState(true);
    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    const [VerificationCode, setVerificationCode] = useState("");
    const [forgotDailog, setforgotDailog] = useState(false);
    const navigate = useNavigate();
    const style = {
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "#CB2704"
            }
        }
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setEmail(data.get('email'));
        let body = {
            email: data.get('email'),
        }
        try {
            let res = await forgotPassword(body);
            setError('Verification code sent!');
            setTimeout(() => {
                setError('');
            }, 4000);
            setforgotDailog(true);
        } catch (err) {
            setError(err.response.data.status.message);
            setTimeout(() => {
                setError('');
            }, 4000);
        }
    };

    const handleClickClosed = () => {
        setforgotDailog(false)
    };

    const submitPassword = async () => {
        let body = {
            email: Email,
            password: Password,
            resetPasswordCode: VerificationCode
        }
        let res = await changePassword(body);
        setError('Password updated successfully');
        setforgotDailog(false);
        setTimeout(() => {
            setError('');
        }, 4000);
        navigate("/");
    };

    setTimeout(() => {
        setLoading(false);
    }, 1000);

    if (Loading) {
        return (
            <div className='loading-card'>
                <img className='loading-img' src={loadImage} alt='Loading...'></img>
            </div>
        );
    } else {
        return (
            <ThemeProvider theme={theme}>
                {error && <CustomizedSnackbars msg={error} isOpen={error !== ""}></CustomizedSnackbars>}
                <Grid container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={false}
                        md={8}
                        sx={{
                            backgroundImage: `url(${loadingImage})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />
                    <Grid item xs={12} sm={12} md={4} component={Paper} elevation={6} square>
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'black' }}>
                                <DashboardIcon />
                            </Avatar>
                            <Typography sx={{ mt: 3, mb: 2, fontWeight: '600' }} component="h1" variant="h5">
                                Reset Password
                            </Typography>
                            <Typography sx={{ fontWeight: '600' }} variant="body2" color="text.secondary" align="center">
                                {`Remember your password? `}
                                <Link color="inherit" href="/">
                                    Login
                                </Link>
                            </Typography>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                <TextField
                                    sx={style}
                                    InputLabelProps={{
                                        sx: {
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "#CB2704"
                                            }
                                        }
                                    }}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2, background: '#CB2704', "&:hover": { background: "red" } }}
                                >
                                    Submit
                                </Button>
                                <Copyright sx={{ mt: 5 }} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Dialog open={forgotDailog} onClose={handleClickClosed}>
                    <DialogTitle>Forget Password</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter verification code and set new password.
                        </DialogContentText>
                        <TextField
                            sx={style}
                            InputLabelProps={{
                                sx: {
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        color: "#CB2704"
                                    }
                                }
                            }}
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Verification Code"
                            type="text"
                            fullWidth
                            onChange={(e) => {
                                setVerificationCode(e.target.value);
                            }}
                        />
                        <TextField
                            sx={style}
                            InputLabelProps={{
                                sx: {
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        color: "#CB2704"
                                    }
                                }
                            }}
                            autoFocus
                            margin="dense"
                            id="name"
                            label="New Password"
                            type="text"
                            fullWidth
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClickClosed}>Cancel</Button>
                        <Button onClick={submitPassword}>Submit</Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        );
    }
}
